import React from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";
import ImageUploader from "react-images-upload";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Helper from "../../../../helper";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

import classes from "./components.css";
import axios from "../../../../axios";
import Header from "components/Headers/Header.jsx";
import validation from "./validation";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let companyId = "";
let equipmentId = "";
let equipmentName = "";
let componentId = "";
let machineId = "";
let componentTypes = [];
class ComponentAdd extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;
  }

  state = {
    component: {
      imagesUrl: [],
      operationalSpecifications: [],
      tag: {},
    },
    componentBackup: {
      imagesUrl: [],
      operationalSpecifications: [],
      tag: {},
    },
    operationalSpecifications: [
      {
        name: "",
        value: "",
      },
    ],
    technicalSpecifications: [],
    componentImages: [],
    componentTypes: [],
    componentSubType: [],
    equipmentTypes: [],
    groups: [],
    readOnlyComponent: true,
    readOnlyLocation: true,
    optData: [],
    //Location state
    readOnlyComponent: true,
    locationImages: [],
    engineeringDrawings: [],
    otherDocs: [],
    locationKey: 1,
    saving: false,
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    equipmentId = this.props.match.params.equipmentId;
    equipmentName = localStorage.getItem("equipmentName");
    machineId = localStorage.getItem("machineId");

    await this.getComponentTypeOptions();
    await this.getGroupOptions();
    await this.getSpecifications();
  }

  async getSpecifications() {
    let endpoint = "/technical-specifications";
    let api = await axios.getData(endpoint);
    let options = [];
    if (api) {
      api &&
        api.data &&
        api.data.data &&
        api.data.data.forEach((s, i) => {
          options.push({
            key: i,
            label: `${s.name} - ${s.units}`,
            value: s.name,
          });
        });
    }
    this.setState({ technicalSpecifications: options });
  }

  async getComponentTypeOptions() {
    let endpoint = `/components/componentTypes/${equipmentId}`;
    componentTypes = await axios.componentType_get();
    let equipTypes = await axios.equipmentType_get();
    let options = [],
      optionsSubtype = [];
    if (componentTypes) {
      Object.keys(componentTypes.data.data).forEach((eq, i) => {
        if (eq !== "_id") {
          equipTypes.data.data[this.props.location.state.equipmentType].map(
            (val) => {
              if (val === eq) {
                options.push({
                  key: i,
                  label: `${eq}`,
                  value: eq,
                });
              }
            }
          );
        }
      });
    }

    this.setState({
      componentTypes: options,
      componentSubType: optionsSubtype,
    });
  }

  async getGroupOptions() {
    let endpoint = "/groups/options/" + companyId;
    let groups = await axios.getData(endpoint);
    let options = [];
    groups &&
      groups.data &&
      groups.data.data &&
      groups.data.data.forEach((group, i) => {
        options.push({
          key: i,
          label: `${group.groupName} - ${group.plantId}`,
          value: group.plantId,
        });
      });

    this.setState({ groups: options });
  }

  handleChange =
    (input, key = null, index = null) =>
    (e) => {
      if (input === "operationalSpecifications") {
        let operationalSpecifications = [
          ...this.state.operationalSpecifications,
        ];
        operationalSpecifications[index].value = e.target.value;
        this.setState({ operationalSpecifications: operationalSpecifications });
      } else {
        let component = { ...this.state.component };
        component[input] = e.target.value;
        this.setState({ component: component });
      }
    };

  handleBearingNumber =
    (input, key = null, index = null) =>
    (e) => {
      if (input === "operationalSpecifications") {
        let operationalSpecifications = [
          ...this.state.operationalSpecifications,
        ];
        operationalSpecifications[index].value = e.target.value;
        this.setState({ operationalSpecifications: operationalSpecifications });
      } else {
        let component = { ...this.state.component };
        component[input] = e.target.value;
        this.setState({ component: component });
      }
    };

  handleGearTeeth =
    (input, key = null, index = null) =>
    (e) => {
      if (input === "operationalSpecifications") {
        let operationalSpecifications = [
          ...this.state.operationalSpecifications,
        ];
        operationalSpecifications[index].value = e.target.value;
        this.setState({ operationalSpecifications: operationalSpecifications });
      } else {
        let component = { ...this.state.component };
        component[input] = e.target.value;
        this.setState({ component: component });
      }
    };

  handleBearingMake =
    (input, key = null, index = null) =>
    (e) => {
      if (input === "operationalSpecifications") {
        let operationalSpecifications = [
          ...this.state.operationalSpecifications,
        ];
        operationalSpecifications[index].value = e.target.value;
        this.setState({ operationalSpecifications: operationalSpecifications });
      } else {
        let component = { ...this.state.component };
        component[input] = e.target.value;
        this.setState({ component: component });
      }
    };

  handleNoOfVanes =
    (input, key = null, index = null) =>
    (e) => {
      if (input === "operationalSpecifications") {
        let operationalSpecifications = [
          ...this.state.operationalSpecifications,
        ];
        operationalSpecifications[index].value = e.target.value;
        this.setState({ operationalSpecifications: operationalSpecifications });
      } else {
        let component = { ...this.state.component };
        component[input] = e.target.value;
        this.setState({ component: component });
      }
    };

  handleGearRatio =
    (input, key = null, index = null) =>
    (e) => {
      if (input === "operationalSpecifications") {
        let operationalSpecifications = [
          ...this.state.operationalSpecifications,
        ];
        operationalSpecifications[index].value = e.target.value;
        this.setState({ operationalSpecifications: operationalSpecifications });
      } else {
        let component = { ...this.state.component };
        component[input] = e.target.value;
        this.setState({ component: component });
      }
    };

  handleChangeLocation =
    (input, key = null, index = null) =>
    (e) => {
      // locationImages
      // engineeringDrawings
      // otherDocs
      let file = e.target.value;
      let encodedFiles = [];
      encodedFiles[0] = file;
      this.setState({ [input]: encodedFiles });
    };

  handleCancel(type) {}

  handleSelectChange =
    (type, key = null, index = null) =>
    async (option) => {
      let optionsSubtype2 = [];
      if (type === "componentType") {
        componentTypes.data.data[option.value].forEach((eq, i) => {
          if (eq !== "_id") {
            optionsSubtype2.push({
              key: i,
              label: `${eq === "suckerRod" ? "sucker-rod" : eq}`,
              value: eq,
            });
          }
        });
        this.setState({
          componentSubType: optionsSubtype2,
        });
      }

      if (type === "operationalSpecifications") {
        let operationalSpecifications = [
          ...this.state.operationalSpecifications,
        ];
        operationalSpecifications[index][key] = option ? option.value : "";
        this.setState({ operationalSpecifications: operationalSpecifications });
      } else {
        let component = { ...this.state.component };
        if (type === "plantId")
          component[type] = option ? parseInt(option.value) : "";
        else component[type] = option ? option.value : "";
        this.setState({ component });
      }
    };

  onDrop = (pictureFiles, pictureData) => {
    let component = { ...this.state.component };
    component.imagesUrl = pictureData;
    this.setState({ component: component });
  };

  fileChangedHandler = (data) => async (event) => {
    let files = event.target.files;
    let encodedFiles = [];
    for (let i = 0; i < files.length; i++) {
      let encoded = await this.getBase64(files[i]);
      encodedFiles.push(encoded);
    }

    // console.log(encodedFiles, 'encoded files');
    this.setState({ componentImages: encodedFiles });
  };

  getBase64(file) {
    let reader = new FileReader();

    //Returning a promise so that we can call it using await
    return new Promise((resolve, reject) => {
      //on error reject it
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file"));
      };

      reader.onload = () => {
        //on successfull resolve it
        resolve(reader.result);
      };

      reader.readAsDataURL(file);
    });
  }

  handleAdd() {
    let operationalSpecifications = [...this.state.operationalSpecifications];
    let obj = {
      name: "",
      value: "",
    };
    operationalSpecifications.push(obj);
    this.setState({ operationalSpecifications });
  }

  handleRemove() {
    let operationalSpecifications = [...this.state.operationalSpecifications];
    operationalSpecifications.pop();
    this.setState({ operationalSpecifications });
  }

  async handleSave() {
    this.setState({ saving: true });
    let component = {
      ...this.state.component,
    };

    //Validating
    let testComponent = { ...component };

    testComponent.operationalSpecifications = [
      ...this.state.operationalSpecifications,
    ];
    let err = await validation.validateComponent(testComponent, true);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    component.companyId = companyId;
    component.equipmentId = equipmentId;

    if (this.state.operationalSpecifications) {
      component.operationalSpecifications = JSON.stringify(
        this.state.operationalSpecifications
      );
    }

    // console.log(component, 'POST Data')
    component = {
      customName: component.customName,
      componentType: component.componentType,
      componentSubType: component.componentSubType,
      equipmentId: parseInt(component.equipmentId),
      externalId: component.externalId,
    };

    let api = await axios.assetManage_ComponentCreate(component);
    if (api && api.status == 200) {
      toast.success("Component saved successfully.");
      toast.success("TagId Generated successfully.");

      if (this.state.componentImages) {
        let compImage = {
          file: this.state.componentImages[0],
          fileName: `${api.data.data.componentId}.jpg`,
          companyId: companyId,
        };
        let api2 = await axios.fileDataImage_create(compImage);
      }

      this.setState({
        readOnlyComponent: true,
        component: api.data.data,
        componentImages: [],
      });
      // this.jump('/v1/equipments/'  + companyId + '/' + equipmentId + '/' + api.data.data.component.componentId);
      // this.jump('/v1/equipment-details/'  + companyId + '/' +  machineId +  '/' + equipmentId, 1500);
      this.jump(
        `/v1/companies/${companyId}/machines/${machineId}/equipments/${equipmentId}`,
        2000
      );
    } else if (api && api.status == 400) {
      toast.warn(api.data.message);
    } else if (api.status == 500) {
      toast.error("Something went wrong. Pease try again");
    }

    this.setState({ saving: false });
  }

  jump = (url, delay = 0) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  };

  render() {
    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <div
                      onClick={() => {
                        this.jump(
                          "/v1/companies/" +
                            companyId +
                            "/machines/" +
                            machineId +
                            "/equipments/" +
                            equipmentId
                        );
                      }}
                      style={{
                        width: "2rem",
                        height: "2rem",
                        cursor: "pointer",
                      }}
                      className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-arrow-left"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <Col xs="8">
                      <h3 className="mb-0">Add Component</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Col xs="12">
                    <h5 style={{ color: "seagreen" }} className="mb-4">
                      Component Details:
                    </h5>
                  </Col>
                  {/* <br/><br/> */}
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Component Name <span className="text-danger">*</span>
                        </label>
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            id="input-customName"
                            type="text"
                            onChange={this.handleChange("customName")}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Component Type{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            isClearable={true}
                            onChange={this.handleSelectChange("componentType")}
                            placeholder=""
                            options={this.state.componentTypes}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Component SubType{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            isClearable={true}
                            onChange={this.handleSelectChange(
                              "componentSubType"
                            )}
                            placeholder=""
                            options={this.state.componentSubType}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Equipment
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-customName"
                            type="text"
                            disabled={true}
                            value={equipmentName}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            External ID{" "}
                            <Tooltip
                              title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                              placement="right-start"
                            >
                              <Info
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  fontSize: "30px",
                                }}
                              />
                            </Tooltip>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            type="text"
                            onChange={this.handleChange("externalId")}
                            disabled={this.state.readOnly}
                          />
                        </FormGroup>
                      </Col>
                      ;{/* <Col lg="6"> */}
                      {/* <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Group
                            </label>
                            <Select
                                isClearable={false}
                                placeholder=""
                                options={this.state.groups}
                                onChange={this.handleSelectChange('plantId')}
                            />
                          </FormGroup> */}
                      {/* </Col> */}
                    </Row>
                    <br />
                    <Row>
                      <Col className="order-xl-1" xl="12">
                        <div className="pl-lg-4">
                          <form>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-email"
                                  >
                                    Component Image
                                  </label>
                                  <br />
                                  <input
                                    type="file"
                                    accept="image/jpeg, image/png"
                                    multiple
                                    key={this.state.locationKey}
                                    onChange={this.fileChangedHandler(
                                      "locationImages"
                                    )}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="12">
                                <Button
                                  className="right"
                                  color="success"
                                  onClick={() => this.handleSave()}
                                  size="md"
                                  disabled={this.state.saving}
                                >
                                  {this.state.saving ? (
                                    <i className="fas fa-spinner fa-pulse"></i>
                                  ) : (
                                    "Save"
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* Location details */}
          </Row>
        </Container>
      </>
    );
  }
}

export default ComponentAdd;
