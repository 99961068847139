import React from "react";
import axios from "../../../../axios";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Chip from "@material-ui/core/Chip";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";
import validation from "./validation";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxisImage from "./axisImage";
import GraphShimmer from "../../../../components/Shimmer/GraphShimmer";

const { SearchBar } = Search;
let windoTypeArr = [];

let levels = {
  rul: 2,
  sensorDataProcessConfigs: 2,
  algorithmPreference: 1,
  powerEnd: 1,
  faultDetection: 1,
};

let companyId = "";
let sensorId = "";
let tagId = "";
let is = [];
let portal_user = "";

class SensorDetails extends React.Component {
  state = {
    currentTab: "rul",
    currentTab2: "clipping_thresholds",
    sensors: [],
    sensorUnit: ["vibration", "ultrasonic", "temperature", "humidity"],
    sensor: {},
    tagId: "",
    sensorTypes: [],
    firmwareTypes: [],
    processConfigurations: [],
    mountConfigurations: [],
    tagOptions: [],
    //Navigations
    currentPage: 4,
    saving: false,
    pages: [
      "main",
      "sensorType",
      "firmwareType",
      "processConfigurations",
      "pageMountConfig",
    ],
    defaultSensorType: "",
    defaultFirmwareType: "",
    defaultMountType: "",
    sensorConfigurations: [],
    firmwareConfigurations: [],
    processConfiguration: {},
    sensorMountInfo: {
      mountMapping: {},
    },
    loading: false,
    expanded: false,
    open: false,
    frequency: [
      {
        value: 1,
        label: "1 Per Day",
      },
      {
        value: 2,
        label: "2 Per Day",
      },
      {
        value: 4,
        label: "4 Per Day",
      },
      {
        value: 6,
        label: "6 Per Day",
      },
    ],
    selectedFrequency: -1,
    saving: false,
    sensorsUploadFrequencySuccess: [],
    sensorsUploadFrequencyError: [],
    createAllowed: false,
    windowTypeSelected: null,
    bearingFaultNum: 1,
    gmfNum: 1,
    rpmCopy: "",
    diabledFields:[ 
      'totalRatio', 
      'shaftNo', 
      'shaftRPM',  
      "numOfTeeth1", 
      "numOfTeeth2", 
      "speedInRpm", 
      "speedOutRpm", 
      "shaftRatio"]
  };

  constructor(props) {
    super(props);

    this.handleSeletChangeUploadRate =
      this.handleSeletChangeUploadRate.bind(this);
    portal_user = JSON.parse(localStorage.getItem("portal-user"));
  }

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    sensorId = this.props.match.params.sensorId
      ? this.props.match.params.sensorId
      : null;
    tagId = this.props.match.params.tagId
      ? this.props.match.params.tagId
      : null;

      console.log(" this.props.match.params.companyId",  this.props)
    this.setState({ loading: true });
    await this.getConfig("processConfig", "5db948b6c77abe4a5784603f");
    if (this.props.location.state.tagId) {
      await this.getSensor(this.props.location.state.tagId);
    } else if (sensorId) {
      await this.getSensor();
    }
    if (this.props.location.state.currentPage) {
      this.setState({ currentPage: this.props.location.state.currentPage });
    } else {
      await this.getUnassignedTags(); //All the unassigned sensors
      await this.getSensorTypes(); //All the sensor types
      await this.getFirmwareTypes(); //All the firmware types
    }
    await this.getMountConfigurations(); //All the mount configuration
    this.setState({ loading: false });

    //Checking Authorization
    let slug = portal_user.department.slug;
    if (["hardware", "admin"].includes(slug)) {
      //Allowing to Change Upload Frequency to hardware group users
      this.setState({ createAllowed: true });
    }
  }

  async getSensor(tempTagId = null) {
    // let endpoint = "/sensors/details/" + sensorId;
    // if (tagId) {
    //   endpoint = endpoint + "/" + tagId;
    // }
    let api = await axios.sensorManage_ProcessConfigGetData(
      tempTagId !== null ? tempTagId : null
    );
    let getSensorDetails = await axios.sensorManage_AssignedDetails(sensorId);
    let mountTypeTemp = await axios.mountConfig_details(
      getSensorDetails.data.data.mountTypeId
    );
    let sensorTypeDetails = await axios.sensorManage_Details(sensorId);
    console.log(
      "apiapiapiapi:",
      getSensorDetails.data.data.mountTypeId,
      mountTypeTemp
    );
    console.log("defaultSensorType: ", this.state.defaultSensorType);
    console.log("sensorTypes:", sensorTypeDetails);

    if (
      this.props.location.state &&
      api.data.data &&
      this.props.location.state.hasOwnProperty("sensorType")
    ) {
      this.setState({ currentPage: 2, tagId: api.data.data.tagId });
    } else if (
      this.props.location.state &&
      api.data.data &&
      this.props.location.state.hasOwnProperty("firmwareType")
    ) {
      this.setState({ currentPage: 3, tagId: api.data.data.tagId });
    } else {
      console.log(api.data);
      this.setState({
        currentPage: 1,
        tagId: api.data.data === null ? null : api.data.data.tagId,
      });
    }

    //Setting up configurations
    this.setState({
      //Saving in state to autoselect the dropdown of each configurations
      sensorType: sensorTypeDetails.data.data.sensorTypeId,
      firmwareType: sensorTypeDetails.data.data.firmwareVersId,
      mountType: mountTypeTemp.data.data,
      mountTypeId: getSensorDetails.data.data.mountTypeId,

      //Configuration data
      sensorConfigurations:
        api.data.data &&
        api.data.data.sensorConfig &&
        api.data.data.sensorConfig.configurations,
      firmwareConfigurations:
        api.data.data &&
        api.data.data.firmwareConfig &&
        api.data.data.firmwareConfig.firmwareConfigurations,
      // processConfiguration: api.data.data.processConfig.processConfigurations,
      sensorMountInfo: mountTypeTemp.data.data
        ? mountTypeTemp.data.data.sensorMountInfo
        : {
            mountMapping: {},
          },
    });

    //Merging nonSelected faults so that they can be selected by user
    let appliedFaultsKey = {};
    appliedFaultsKey =
      api.data.data &&
      api.data.data.processConfigurations &&
      api.data.data.processConfigurations.faultDetection
        ? Object.keys(api.data.data.processConfigurations.faultDetection)
        : null;
    console.log("appliedFaultsKey", appliedFaultsKey);
    let nonAppliedFaults = {};
    Object.keys(this.state.faultDetection).map((fault) => {
      if (appliedFaultsKey) {
        if (!appliedFaultsKey.includes(fault)) {
          nonAppliedFaults[fault] = this.state.faultDetection[fault];
        }
      } else {
        nonAppliedFaults[fault] = this.state.faultDetection[fault];
      }
    });
    if (api.data.data) {
      let faultDetection = {
        ...api.data.data.processConfigurations.faultDetection,
        ...nonAppliedFaults,
      };
      let processConfigurationsCopy = {
        ...api.data.data.processConfigurations,
      };

      processConfigurationsCopy.faultDetection = faultDetection;
      const bearingFaultAllValues =
        processConfigurationsCopy.faultDetection.bearing_faults;
      processConfigurationsCopy = this.setBearignFaultInputFields(
        processConfigurationsCopy,
        bearingFaultAllValues
      );
      this.setState({
        rpmCopy:
          processConfigurationsCopy.rul.faultFrequencyAmplitude.rpm || "",
      });
      if (
        processConfigurationsCopy &&
        processConfigurationsCopy.windowType &&
        Array.isArray(
          processConfigurationsCopy.windowType &&
            processConfigurationsCopy.windowType.field
        )
      ) {
        windoTypeArr = processConfigurationsCopy["windowType"]["field"].map(
          (valTemp) => {
            return { key: valTemp, label: valTemp, value: valTemp };
          }
        );
        this.setState({
          processConfiguration: processConfigurationsCopy,
          windowTypeSelected: windoTypeArr[0],
        });
      } else {
        windoTypeArr = [
          { key: "hanning", label: "hanning", value: "hanning" },
          { key: "hamming", label: "hamming", value: "hamming" },
          { key: "rectangular", label: "rectangular", value: "rectangular" },
        ];
        this.setState({
          processConfiguration: processConfigurationsCopy,
          windowTypeSelected: [
            {
              key:
                processConfigurationsCopy.windowType &&
                processConfigurationsCopy.windowType,
              label:
                processConfigurationsCopy.windowType &&
                processConfigurationsCopy.windowType,
              value:
                processConfigurationsCopy.windowType &&
                processConfigurationsCopy.windowType,
            },
          ],
        });
      }
    }
  }

  setBearignFaultInputFields(data, bearingFaultAllValues) {
    const variableBearingFaultObj = {
      bpfiAmpThresholdVel: "",
      bpfiAmpThresholdAcc: "1",
      bpfoAmpThresholdVel: "",
      bpfoAmpThresholdAcc: "1",
      bsfAmpThresholdVel: "",
      bsfAmpThresholdAcc: "1",
      apply: bearingFaultAllValues.apply,
      bearingNumber: "",
      bpfiCoeff: "",
      bpfoCoeff: "",
      bsfCoeff: "",
      exclusiveCoeff: "",
      exclusiveHarmonics: "",
      ftfCoeff: "",
      exclusiveFreq: "",
      totalRatio:null, 
      shaftNo:null, 
      shaftRPM:null
    };
    const bearingFaults = [];

    bearingFaultAllValues.map((oneObject) => {
      let bearingFaultsobj = {};
      const allKeys = Object.keys(variableBearingFaultObj);
      allKeys.map((ele) => {
        bearingFaultsobj[`${ele}`] = oneObject[`${ele}`] || "";
      });
      bearingFaults.push(bearingFaultsobj);
    });

    data.faultDetection.bearing_faults = bearingFaults;
    return data;
  }

  async getSensorTypes() {
    let api = await axios.getSensorTypes();
    let options = [];
    api.data.data.forEach((sen, i) => {
      options.push({
        key: i,
        label: sen.sensorType,
        value: sen._id,
        id: sen._id,
      });
    });

    if (sensorId) {
      let index = options.findIndex((x) => x.value === this.state.sensorType);
      this.setState({
        defaultSensorType: index,
        defaultSensorTypeBackup: index,
      });
    }

    this.setState({ sensorTypes: options });
  }

  async getFirmwareTypes() {
    let api = await axios.getFirmwareTypes();
    let options = [];
    api.data.data.forEach((sen, i) => {
      options.push({
        key: i,
        label: sen.firmwareType,
        value: sen._id,
        id: sen._id,
      });
    });

    if (sensorId) {
      let index = options.findIndex((x) => x.value === this.state.firmwareType);
      this.setState({
        defaultFirmwareType: index,
        defaultFirmwareTypeBackup: index,
      });
    }

    this.setState({ firmwareTypes: options });
  }

  async getMountConfigurations() {
    let endpoint = "/mount-configs";
    let api = await axios.mountConfig_List();
    let options = [];
    api.data.data.forEach((sen, i) => {
      options.push({
        key: i,
        label: sen.label,
        value: sen._id,
        id: sen._id,
      });
    });

    if (sensorId) {
      let index = options.findIndex((x) => x.value === this.state.mountTypeId);
      this.setState({ defaultMountType: index, defaultMountTypeBackup: index });
    }

    this.setState({ mountConfigurations: options });
  }

  async getUnassignedTags() {
    const response = await Promise.all([
      axios.assetManage_ComponentList(companyId),
    ]);

    let tagIdList = [];
    if (response[0].status === 200) tagIdList = response[0].data.data || [];

    tagIdList = tagIdList.filter((x) => {
      return x.tagId !== null && x.tagId !== undefined;
    });
    const tagOptions = [];
    let selectedIndex = 0;
    tagIdList.map(({ tagId }, key) => {
      if (!tagId) return;
      if (tagId === this.state.tagId) selectedIndex = key;

      tagOptions.push({ key, label: tagId, value: tagId });
    });

    this.setState({
      defaultTagId: selectedIndex,
      defaultTagIdBackup: selectedIndex,
    });
    this.setState({ tagOptions });
  }

  reduce = (obj, key, val) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.reduce(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        val
      );
    }
    if (keySplit.length == 1) {
      obj[key] = val;
      return obj[key];
    } else {
      obj = val;
      return obj;
    }
  };

  handleChange =
    (obj, key, index = -1, type = "string") =>
    (e) => {
      let copy = { ...this.state[obj] };
      let value = type === "string" ? e.target.value : e.target.checked;

      if (index >= 0) {
        copy[index][key] = value;
        let config = [...this.state.sensorConfigurations];
        this.setState({ sensorConfigurations: config });
      } else {
        copy[key] = value;
        this.setState({ [obj]: copy });
      }
    };

  handleChangeProcess =
    (param, input, type = "string") =>
    (e) => {
      let copy = { ...this.state[param] };
      if (type === "array") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, [val]);
      } else if (type === "checkbox") {
        this.reduce(copy, input, e.target.checked);
      } else if (type === "number") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, val);
      } else {
        this.reduce(copy, input, e.target.value);
      }
      this.setState({ [param]: copy });
    };

  handleGMFGearFaultProcess = (param, input, key) => (e) => {
    let copy = { ...this.state[param] };
    let tempAssign = input.split(".");
    copy[tempAssign[0]][tempAssign[1]][key][tempAssign[3]] = e.target.value;
    this.setState({ [param]: copy });
  };

  handleGMFGearFaultAdd = (param) => {
    let variableBearingFaultObj = {
        bpfiAmpThresholdVel: "",
        bpfiAmpThresholdAcc: "1",
        bpfoAmpThresholdVel: "",
        bpfoAmpThresholdAcc: "1",
        bsfAmpThresholdVel: "",
        bsfAmpThresholdAcc: "1",
        apply: true,
        bearingNumber: "",
        bpfiCoeff: "",
        bpfoCoeff: "",
        bsfCoeff: "",
        exclusiveCoeff: "",
        exclusiveHarmonics: "",
        ftfCoeff: "",
        exclusiveFreq: "",
        totalRatio:null, 
        shaftNo:null, 
        shaftRPM:null
      },
      gear_fault = {
        apply: true,
        gmf: "",
        numOfGearTeeth: "",
        gmfThresholdAcc: "20",
        gEThresholdAcc: "5",
        gearRatio: "",
        rpmDevRatio: "0.1",
        sidebandOrder: "5",
        envRpmOrderLow: "0.8",
        envRpmOrderHigh: "3.2",
        numOfTeeth1:"", 
        numOfTeeth2:"", 
        speedInRpm:"", 
        speedOutRpm:"", 
        shaftRatio:""
      };
    let copy = { ...this.state.processConfiguration };
    if (param === "gear_fault") {
      copy.faultDetection[param].push(gear_fault);
    } else {
      const checkRpm = copy.rul.faultFrequencyAmplitude.rpm.includes("-");
      const bearingFaultObj = variableBearingFaultObj;

      copy.faultDetection[param].push(bearingFaultObj);
    }

    this.setState({ processConfiguration: copy });
  };

  handleSeletChangeUploadRate(option) {
    let index = this.state.frequency.findIndex((x) => x.value === option.value);
    this.setState({ selectedFrequency: index });
  }

  handleSelectChange =
    (state, key = null) =>
    async (option) => {
      let copy = { ...this.state[state] };
      if (key) {
        copy[key] = option ? option.value : "";
      } else {
        copy = option.value;
      }

      let defaultKey = "";
      let value = option ? option.value : "";
      let defaultValue = option ? option.key : 0;

      if (state === "sensorType") {
        defaultKey = "defaultSensorType";

        // Call API and fetch sensorType data from backend and set in state
        await this.getConfig("sensorType", value);
      }

      if (state === "firmwareType") {
        defaultKey = "defaultFirmwareType";

        // Call API and fetch sensorType data from backend and set in state
        await this.getConfig("firmwareType", value);
      }

      if (state === "mountType") {
        defaultKey = "defaultMountType";

        // Call API and fetch sensorType data from backend and set in state
        await this.getConfig("mountType", value);
      }

      if (key === "tagId") {
        defaultKey = "defaultTagId";
        this.setState({ tagId: value });
        //Call API and fetch tagConfigMap data from server and set in state
        // if (option.value && this.state.sensor.sensorType)
        //   await this.getConfig(option.value, this.state.sensor.sensorTypeId);
      }

      this.setState({ [state]: copy, [defaultKey]: defaultValue });
    };

  async getConfig(object, id) {
    if (object === "processConfig") {
      let api = await axios.get_Process_config();

      let data = api.data.data;
      this.setState({
        faultDetection: data.processConfigurations.faultDetection,
        processConfigurationMaster: data.processConfigurations,
      });
    }
    if (object === "sensorType") {
      let api = await axios.getSensorType_byId(id);
      let data = api.data.data;

      this.setState({ sensorConfigurations: data.sensorConfigurations });
    }

    if (object === "firmwareType") {
      let api = await axios.getFirmwareType_byId(id);
      let data = api.data.data;

      this.setState({ firmwareConfigurations: data.firmwareConfigurations });
    }

    if (object === "mountType") {
      let api = await axios.mountConfig_details(id);
      let data = api.data.data;

      this.setState({ sensorMountInfo: data.sensorMountInfo });
    }
  }

  handleNext = async (page) => {
    if (this.state.currentPage === 5) {
      let conf = this.state.sensorMountInfo.mountType;
      if (!conf) {
        toast.warn("Please select Sensor Mount Configuration");
        this.setState({ saving: false });
        return;
      }

      this.setState({ saving: true });
      localStorage.removeItem("companyId");

      //Sensor Config
      let sensorConfig = {};
      // sensorConfig.tagId = this.state.tagId;
      // sensorConfig.sensorId = sensorId;
      // sensorConfig.sensorType = this.state.sensorType;
      // sensorConfig.configurations = [
      //   { ...this.state.sensorConfigurations[0] },
      //   { ...this.state.sensorConfigurations[1] },
      //   { ...this.state.sensorConfigurations[2] },
      //   { ...this.state.sensorConfigurations[3] },
      // ];
      // console.log(sensorConfig, 'sensor Configuration');

      //Firmware Config
      let firmwareConfigurations = {};
      // firmwareConfigurations.firmwareType = this.state.firmwareType;
      // firmwareConfigurations.firmwareConfigurations = [
      //   { ...this.state.firmwareConfigurations[0] },
      //   { ...this.state.firmwareConfigurations[1] },
      //   { ...this.state.firmwareConfigurations[2] },
      //   { ...this.state.firmwareConfigurations[3] },
      // ];
      firmwareConfigurations.tagId = this.state.tagId;
      // console.log(processConfigurations, 'processConfigurations');

      //Process Config
      let processConfigurations = {};
      processConfigurations.processConfigurations = {
        ...this.state.processConfiguration,
      };
      processConfigurations.tagId = this.state.tagId;
      processConfigurations.processConfigurations.windowType =
        this.state.windowTypeSelected.value;
      // console.log(processConfigurations, 'processConfigurations');

      let tempFaultDetection = {},
        tempAEFaultDetection = {};
      Object.entries(
        processConfigurations.processConfigurations.faultDetection
      ).forEach(([key, value]) => {
        if (value.apply === false) return;
        tempFaultDetection = { ...tempFaultDetection, [key]: value };
      });

      if (processConfigurations.processConfigurations.aeFaultDetectio) {
        Object.entries(
          processConfigurations.processConfigurations.aeFaultDetection
        ).forEach(([key, value]) => {
          if (value.apply === false) return;
          tempAEFaultDetection = { ...tempAEFaultDetection, [key]: value };
        });
      }

      processConfigurations.processConfigurations.faultDetection =
        tempFaultDetection;
      processConfigurations.processConfigurations.aeFaultDetection =
        tempAEFaultDetection;

      //Sensor Mount Config
      let sensorMountConfig = {};
      sensorMountConfig.mountType = this.state.mountType;
      sensorMountConfig.sensorMountInfo = { ...this.state.sensorMountInfo };
      sensorMountConfig.tagId = this.state.tagId;
      sensorMountConfig.sensorId = sensorId;
      sensorMountConfig.description = "";
      delete sensorMountConfig.sensorMountInfo.description;
      // console.log(sensorMountConfig, 'sensorMountConfiguration');

      let data = {
        sensorConfig: sensorConfig,
        sensorFirmwareConfig: firmwareConfigurations,
        sensorProcessConfig: processConfigurations,
        sensorMountConfig: sensorMountConfig,
      };

      // console.log(data);
      // return;

      let sensorApiStatus = "";
      let sensorApiMessage = "";

      //Saving Configuration changes
      let sensorConfigAPI = await this.saveSensorConfig(data, this.state.tagId);
      if (sensorConfigAPI.status === 200) {
        toast.success("Data saved successfully.", 3000);
        this.jump("/v1/companies/" + companyId + "/sensors", 3000);
      } else if (sensorConfigAPI.status === 400) {
        toast.warn(sensorConfigAPI.message);
        this.setState({ saving: false });
      } else if (sensorConfigAPI.status === 500) {
        toast.error("Something went wrong. Please try again.");
        this.setState({ saving: false });
      }
    } else {
      let valid = true;
      if (this.state.currentPage === 1) {
        // let sensor = {...this.state.sensor};
        // //Validating
        // let err = await validation.validateDetails(sensor);
        // if (err) {
        //   toast.warn(err);
        //   this.setState({saving: false});
        //   return;
        // }
      }

      if (this.state.currentPage === 4) {
        //Checking if single fault is selected or not
        let faultDetection = this.state.processConfiguration.faultDetection;
        let valid = false;
        Object.keys(faultDetection).forEach((f) => {
          if (faultDetection[f].apply) {
            valid = true;
          }
          if (f === "gear_fault" || f === "bearing_faults") {
            if (faultDetection[f][0]["apply"]) {
              valid = true;
            }
          }
        });

        if (!valid) {
          toast.warn("Select atleast one fault");
          this.setState({ saving: false });
          return;
        }
      }

      if (valid) this.setState({ currentPage: page });
    }
    // this.setState({saving: false});
  };

  handleCancel = (page) => {
    if (page === "main") {
      this.jump("/v1/companies/" + companyId + "/sensors");
    }
    console.log("page: ", page);
    if (page === "sensorType") {
      this.setState({ currentPage: 1 });
    } else {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
  };

  async saveSensor(sensor) {
    let sensorAPI = await axios.sensorManage_Assign(sensor);
    if (sensorAPI.status === 400 || sensorAPI.status === 404) {
      toast.warn(sensorAPI.data.data);
    }
    return {
      status: sensorAPI.status,
      message: sensorAPI.data.message,
    };
  }

  async saveSensorConfig(sensorConfig, tagId) {
    // let link = `https://sensorapi.nanoprecisedataservices.com/coordinator/api/v1/sensors-keys?sensorIdList=${
    //   sensorConfig.sensorMountConfig.sensorId
    // }&logglyTag=${companyId}-${null}-${
    //   sensorConfig.sensorMountConfig.sensorId
    // }`;

    // let apiTest = await axios.putReq(link, {});
    // console.log("apiTestapiTestapiTest:", apiTest);

    // const requestOptions = {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Basic ZXlKaGJHY2lPaUpJVXpJMU5pSjkuZXlKcFpDSTZJbVZzWldOMGNtbGpTVzF3SW4wLjQ2eTdsVjNrUl9ZNlBVOFpNYVFxUHZMcV94aTFWcEwwbUhvempUWVFkSHc6ZTRjZjVkN2ViMDkwNGJmYmFhZTIyOTQ0MGNlMGYzYWM=`,
    //   },
    // };

    // if (
    //   sensorConfig.sensorProcessConfig &&
    //   sensorConfig.sensorProcessConfig.processConfigurations &&
    //   sensorConfig.sensorProcessConfig.processConfigurations
    //     .notRunningCondition &&
    //   sensorConfig.sensorProcessConfig.processConfigurations.notRunningCondition
    //     .accRmsThreshold
    // ) {
    //   parseFloat(
    //     sensorConfig.sensorProcessConfig.processConfigurations
    //       .notRunningCondition.accRmsThreshold
    //   );
    // }
    Object.assign(
      sensorConfig.sensorProcessConfig.processConfigurations
        .notRunningCondition,
      {
        accRmsThreshold: parseFloat(
          sensorConfig.sensorProcessConfig.processConfigurations
            .notRunningCondition.accRmsThreshold
        ),
      }
    );

    const tempFault =
      sensorConfig.sensorProcessConfig &&
      sensorConfig.sensorProcessConfig.processConfigurations &&
      sensorConfig.sensorProcessConfig.processConfigurations.faultDetection;
    if (!tempFault.bearing_faults[0].apply) {
      delete sensorConfig.sensorProcessConfig.processConfigurations
        .faultDetection.bearing_faults;
    }
    if (!tempFault.gear_fault[0].apply) {
      delete sensorConfig.sensorProcessConfig.processConfigurations
        .faultDetection.gear_fault;
    }
    // let data = {
    //   dataPayload: [
    //     {
    //       sensorId: sensorConfig.sensorMountConfig.sensorId,
    //       tag: `${companyId}-${null}-${this.state.currentSensor?.sensorId}`,
    //     },
    //   ],
    //   referenceId: Math.floor(10000 + Math.random() * 90000),
    // };
    // const responseTemp = await axios.put_sensorCoordinator(data);

    let sensorConfigAPI = await axios.sensorManage_ProcessConfigUpdate(
      this.state.tagId === undefined
        ? this.state.tagOptions &&
            this.state.tagOptions[this.state.defaultTagId].value
        : this.state.tagId,
      {
        processConfigurations:
          sensorConfig.sensorProcessConfig.processConfigurations,
      }
    );

    let sensorMountConfig = await axios.sensorManage_Reassign(
      sensorConfig.sensorMountConfig.sensorId,
      {
        mountTypeId:
          sensorConfig.sensorMountConfig.mountType &&
          sensorConfig.sensorMountConfig.mountType._id
            ? sensorConfig.sensorMountConfig.mountType._id
            : sensorConfig.sensorMountConfig.mountType,
      }
    );

    return {
      status: sensorConfigAPI.status,
      message: sensorConfigAPI.data.message,
    };
  }

  handleUnassign = async () => {
    let response = await axios.sensorManage_Unassign(sensorId);
    // let response2 = await axios.componentConfig_deleteOld(this.state.tagId);
    if (response.status === 200) {
      toast.success("Sensor unassigned successfully.");
      this.jump("/v1/companies/" + companyId + "/sensors", 1000);
    } else if (response.status === 400) {
      toast.warn(response.data.data);
      this.setState({ saving: false });
    } else if (response.status === 500) {
      toast.error("Something went wrong. Please try again.");
      this.setState({ saving: false });
    }
  };

  handleRestore = async () => {
    this.setState({
      processConfiguration: { ...this.state.processConfigurationMaster },
    });
  };

  jump(url, delay = 0) {
    let backURL = url;
    if (this.props.location.state && this.props.location.state.backUrl)
      backURL = this.props.location.state.backUrl;
    setTimeout(() => {
      this.props.history.push(backURL);
    }, delay);
  }

  handleExpandChange = (panel) => (e, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  handleTabChange = (index, lastindex, event) => {
    let currentTab = Object.keys(this.state.processConfiguration)[index];
    let currentTab2 = Object.keys(
      this.state.processConfiguration[currentTab]
    )[0];
    let objectLevel = levels[currentTab];

    this.setState({
      currentTab: currentTab,
      currentTab2: currentTab2,
      objectLevel: objectLevel,
    });
  };

  handleTabChange2 = (index, lastindex, event) => {
    this.setState({
      currentTab2: Object.keys(
        this.state.processConfiguration[this.state.currentTab]
      )[index],
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleUploadRateSubmit = async () => {
    if (this.state.selectedFrequency < 0) {
      toast.warn("Select Upload Frequency");
      return;
    }
    this.setState({ saving: true });

    let endpoint = "/upload-frequency/sensors";

    let data = {
      sensorId: sensorId,
      uploadRate: this.state.frequency[this.state.selectedFrequency].value,
    };

    let api = await axios.postData(endpoint, data);
    if (api.status === 200) {
      toast.success("Operation Successfull");
      let sensorsUploadFrequencyError = api.data.filter((sensor) => {
        return sensor.resStatus != 200;
      });
      let sensorsUploadFrequencySuccess = api.data.filter((sensor) => {
        return sensor.resStatus === 200;
      });
      this.setState({
        saving: false,
        sensorsUploadFrequencySuccess,
        sensorsUploadFrequencyError,
      });
    } else if (api.status === 400) {
      toast.warn(api.data.error);
      this.setState({ saving: false });
    } else {
      toast.error("Something went wrong. Please try again.");
      this.setState({ saving: false });
    }
  };

  render() {
    let processConfig = "";

    processConfig = Object.keys(this.state.processConfiguration).map(
      (field1, i) => {
        return (
          <TabPanel style={{ marginBottom: "20px" }}>
            {levels[field1] === 2 ? (
              <Tabs onSelect={this.handleTabChange2}>
                <TabList>
                  {Object.keys(this.state.processConfiguration[field1]).map(
                    (field2, i) => {
                      return (
                        <Tab key={i} style={{ color: "#e57373" }}>
                          {field2}
                        </Tab>
                      );
                    }
                  )}
                </TabList>
                {Object.keys(this.state.processConfiguration[field1]).map(
                  (field2, i) => {
                    return (
                      <TabPanel key={i}>
                        <Row className="mt-5">
                          {Object.keys(
                            this.state.processConfiguration[field1][field2]
                          ).map((field3, i) => {
                            let inputType = "text";
                            let type = "";
                            if (
                              field2 === "faultFrequencies" ||
                              field2 === "faultFrequencyThresholds" ||
                              field2 === "powerFunctionCoefficients"
                            ) {
                              type = "array";
                              inputType = "number";
                            }
                            if (
                              field2 === "clipping_thresholds" ||
                              field2 === "faultFrequencyData" ||
                              field2 === "vibration" ||
                              field2 === "ultrasonic"
                            ) {
                              type = "number";
                              inputType = "number";
                            }
                            if (field2 === "boundLimits") {
                              type = field3 === "lowerLimit" ? "number" : "";
                              inputType =
                                field3 === "lowerLimit" ? "number" : "";
                            }

                            //comment this to have other types of data as well
                            // type = 'string'

                            return (
                              <Col key={i} lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    {field3}
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-username"
                                    type={inputType}
                                    value={
                                      this.state.processConfiguration[field1][
                                        field2
                                      ][field3]
                                    }
                                    onChange={this.handleChangeProcess(
                                      "processConfiguration",
                                      `${field1}.${field2}.${field3}`,
                                      type
                                    )}
                                    onBlur={(e) => {
                                      if (
                                        `${field1}.${field2}.${field3}` ===
                                        `rul.faultFrequencyAmplitude.rpm`
                                      ) {
                                        if (
                                          (e.target.value.includes("-") &&
                                            !this.state.rpmCopy.includes(
                                              "-"
                                            )) ||
                                          (!e.target.value.includes("-") &&
                                            this.state.rpmCopy.includes("-"))
                                        ) {
                                          this.setBearignFaultInputFields(
                                            this.state.processConfiguration,
                                            this.state
                                              .processConfigurationMaster
                                              .faultDetection.bearing_faults
                                          );
                                        }
                                        if (e.target.value === "") {
                                          // if rmp empty remove selected bearing_faluts
                                          const processConfigurationCopy =
                                            this.state.processConfiguration;
                                          const bearingFaults =
                                            processConfigurationCopy.faultDetection.bearing_faults.map(
                                              (ele) => ({
                                                ...ele,
                                                apply: false,
                                              })
                                            );
                                          this.setState({
                                            processConfiguration: {
                                              ...processConfigurationCopy,
                                              faultDetection: {
                                                ...processConfigurationCopy.faultDetection,
                                                bearing_faults: bearingFaults,
                                              },
                                            },
                                          });
                                        }
                                        this.setState({
                                          rpmCopy: e.target.value,
                                        });
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            );
                          })}
                        </Row>
                      </TabPanel>
                    );
                  }
                )}
              </Tabs>
            ) : (
              <Row className="mt-5">
                {field1 != "faultDetection" && field1 != "aeFaultDetection"
                  ? Object.keys(this.state.processConfiguration[field1]).map(
                      (field2, i) => {
                        return (
                          <Col key={i} lg="4">
                            <FormGroup>
                              <label className="form-control-label">
                                {field1 !== "windowType" ? field2 : null}
                              </label>
                              {field2 != "resampling" ? (
                                <span>
                                  {field1 === "windowType" ? (
                                    <span>
                                      {i === 0 ? (
                                        <Select
                                          onChange={(e) => {
                                            this.setState({
                                              windowTypeSelected: e,
                                            });
                                          }}
                                          value={this.state.windowTypeSelected}
                                          options={windoTypeArr}
                                        />
                                      ) : null}
                                    </span>
                                  ) : (
                                    <Input
                                      className="form-control-alternative"
                                      id="input-username"
                                      type="text"
                                      value={
                                        this.state.processConfiguration[field1][
                                          field2
                                        ]
                                      }
                                      readOnly={this.state.readOnly}
                                      onChange={this.handleChangeProcess(
                                        "processConfiguration",
                                        `${field1}.${field2}`
                                      )}
                                    />
                                  )}
                                </span>
                              ) : (
                                <>
                                  <br />
                                  <Checkbox
                                    checked={
                                      this.state.processConfiguration[field1][
                                        field2
                                      ]
                                    }
                                    onChange={this.handleChangeProcess(
                                      "processConfiguration",
                                      `${field1}.${field2}`,
                                      "checkbox"
                                    )}
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "primary checkbox",
                                    }}
                                    disabled={this.state.readOnly}
                                  />
                                </>
                              )}
                            </FormGroup>
                          </Col>
                        );
                      }
                    )
                  : Object.keys(this.state.processConfiguration[field1]).map(
                      (fault, i) => {
                        if (
                          fault === "bearing_faults" ||
                          fault === "gear_fault"
                        ) {
                          return (
                            <Container maxWidth="sm">
                              <ExpansionPanel
                                expanded={this.state.expanded === "panel" + i}
                                onChange={this.handleExpandChange("panel" + i)}
                              >
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-label="Expand"
                                  aria-controls="additional-actions1-content"
                                  id="additional-actions1-header"
                                >
                                  <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={
                                      <Checkbox
                                        checked={
                                          fault === "gear_fault" ||
                                          fault === "bearing_faults"
                                            ? fault === "gear_fault" ||
                                              (fault === "bearing_faults" &&
                                                this.state.rpmCopy !== "")
                                              ? Boolean(
                                                  this.state
                                                    .processConfiguration[
                                                    field1
                                                  ][fault][0]["apply"]
                                                )
                                              : null
                                            : Boolean(
                                                this.state.processConfiguration[
                                                  field1
                                                ][fault]["apply"]
                                              )
                                        }
                                        onChange={(e) => {
                                          let processConfiguration = {
                                            ...this.state.processConfiguration,
                                          };

                                          if (
                                            fault === "gear_fault" ||
                                            fault === "bearing_faults"
                                          ) {
                                            if (
                                              fault === "gear_fault" ||
                                              (fault === "bearing_faults" &&
                                                this.state.rpmCopy !== "")
                                            ) {
                                              processConfiguration[field1][
                                                fault
                                              ][0]["apply"] = e.target.checked;
                                            }
                                          } else {
                                            processConfiguration[field1][fault][
                                              "apply"
                                            ] = e.target.checked;
                                          }
                                          this.setState({
                                            processConfiguration,
                                          });
                                          // console.log(e.target.checked)
                                          // console.log(processConfiguration['faultDetection'][fault]['apply'])
                                        }}
                                      ></Checkbox>
                                    }
                                    label={fault}
                                  />
                                </ExpansionPanelSummary>

                                <ExpansionPanelDetails>
                                  {fault === "bearing_faults" &&
                                  this.state.rpmCopy === "" ? (
                                    <Container maxWidth="sm">
                                      Please provide the rpm value
                                    </Container>
                                  ) : (
                                    <Container maxWidth="sm">
                                      <Row>
                                        <Button
                                          onClick={(e) => {
                                            switch (fault) {
                                              case "bearing_faults":
                                                this.handleGMFGearFaultAdd(
                                                  "bearing_faults"
                                                );
                                                break;
                                              case "gear_fault":
                                                this.handleGMFGearFaultAdd(
                                                  "gear_fault"
                                                );
                                                break;

                                              default:
                                                break;
                                            }
                                          }}
                                          disabled={this.props.location.state.componentDetails.componentType === 'gearbox'}
                                        >
                                          ADD NEW
                                        </Button>
                                      </Row>
                                      <Row>
                                        {this.state.processConfiguration[
                                          field1
                                        ][fault].map((iArrFault, key) => {
                                          return (
                                            <Row>
                                              {"\n"}
                                              {"\n"}
                                              {"\n"}
                                              <label> </label>
                                              <label>
                                                <b>{key}</b>
                                              </label>
                                              {Object.keys(
                                                this.state.processConfiguration[
                                                  field1
                                                ][fault][key]
                                              ).map((field2, i) => {
                                                if (field2 != "apply") {
                                                  let element = [];
                                                  element.push(
                                                    <Col key={i} lg="3">
                                                      <FormGroup>
                                                        <label className="form-control-label">
                                                          {field2}
                                                        </label>
                                                        <Input
                                                          disabled={
                                                            this.state.readOnly || this.state.diabledFields.includes(field2)
                                                          }
                                                          className="form-control-alternative"
                                                          id="input-username"
                                                          type="text"
                                                          value={
                                                            this.state
                                                              .processConfiguration[
                                                              field1
                                                            ][fault][key][
                                                              field2
                                                            ]
                                                          }
                                                          onChange={this.handleGMFGearFaultProcess(
                                                            "processConfiguration",
                                                            `${field1}.${fault}.${key}.${field2}`,
                                                            key
                                                          )}
                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                  );
                                                  return element;
                                                }
                                              })}
                                            </Row>
                                          );
                                        })}
                                      </Row>
                                    </Container>
                                  )}
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </Container>
                          );
                        }
                        return (
                          <Container maxWidth="sm">
                            <ExpansionPanel
                              expanded={this.state.expanded === "panel" + i}
                              onChange={this.handleExpandChange("panel" + i)}
                            >
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions1-content"
                                id="additional-actions1-header"
                              >
                                <FormControlLabel
                                  aria-label="Acknowledge"
                                  onClick={(event) => event.stopPropagation()}
                                  onFocus={(event) => event.stopPropagation()}
                                  control={
                                    <Checkbox
                                      checked={Boolean(
                                        this.state.processConfiguration[field1][
                                          fault
                                        ]["apply"]
                                      )}
                                      onChange={(e) => {
                                        let processConfiguration = {
                                          ...this.state.processConfiguration,
                                        };
                                        processConfiguration[field1][fault][
                                          "apply"
                                        ] = e.target.checked;
                                        this.setState({ processConfiguration });
                                        // console.log(e.target.checked)
                                        // console.log(processConfiguration['faultDetection'][fault]['apply'])
                                      }}
                                    ></Checkbox>
                                  }
                                  label={fault}
                                />
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails>
                                <Container maxWidth="sm">
                                  <Row>
                                    {Object.keys(
                                      this.state.processConfiguration[field1][
                                        fault
                                      ]
                                    ).map((field2, i) => {
                                      if (field2 != "apply") {
                                        return (
                                          <Col key={i} lg="3">
                                            <FormGroup>
                                              <label className="form-control-label">
                                                {field2}
                                              </label>

                                              <Input
                                                disabled={this.state.readOnly}
                                                className="form-control-alternative"
                                                id="input-username"
                                                type="text"
                                                value={
                                                  this.state
                                                    .processConfiguration[
                                                    field1
                                                  ][fault][field2]
                                                }
                                                onChange={this.handleChangeProcess(
                                                  "processConfiguration",
                                                  `${field1}.${fault}.${field2}`
                                                )}
                                              />
                                            </FormGroup>
                                          </Col>
                                        );
                                      }
                                    })}
                                  </Row>
                                </Container>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Container>
                        );
                      }
                    )}
              </Row>
            )}
          </TabPanel>
        );
      }
    );

    let label = "";
    if (this.state.currentPage === 1) label = "Sensor";
    else if (this.state.currentPage === 2) label = "Sensor Configurations";
    else if (this.state.currentPage === 3) label = "Firmware Configuration";
    else if (this.state.currentPage === 4) label = "Process Configuration";
    else if (this.state.currentPage === 5) label = "Mount Configurations";

    let back = "";
    if (this.state.currentPage === 1) back = "Cancel";
    else if (this.state.currentPage === 2) back = "Sensor";
    else if (this.state.currentPage === 3) back = "Sensor Configuration";
    else if (this.state.currentPage === 4) back = "Sensor";
    //"Firmware Configuration"
    else if (this.state.currentPage === 5) back = "Process Configuration";

    let front = "";
    if (this.state.currentPage === 1) front = "Process Configurations";
    //"Sensor"
    else if (this.state.currentPage === 2) front = "Firmware Configuration";
    else if (this.state.currentPage === 3) front = "Process Configurations";
    else if (this.state.currentPage === 4) front = "Mount Configuration";
    else if (this.state.currentPage === 5) front = "Submit";

    let backURL = "";
    if (tagId) {
      backURL = "/v1/companies/" + companyId + "/sensors/" + sensorId + "/tags";
    } else {
      backURL = "/v1/companies/" + companyId + "/sensors";
    }
    return (
      <>
        <ToastContainer position="top-left" />
        {this.state.loading ? (
          <GraphShimmer />
        ) : (
          <>
            {" "}
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
              <Row>
                <Col lg="6">
                  <div
                    onClick={() => {
                      this.jump(backURL, 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                </Col>
                <Col lg="6 table-caption">
                  <h1>Sensor Configurations</h1>
                </Col>
              </Row>
              <Row>
                <div className="col">
                  <Card className="bg-secondary shadow">
                    {/* Add Sensor */}
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col xs="7">
                          {this.state.currentPage > 0 && (
                            <h3 className="mb-0">{label}</h3>
                          )}
                        </Col>
                        <Col className="text-right" xs="5">
                          {this.state.currentPage === 4 ? (
                            <Button
                              color="danger"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to reset processConfigurations?"
                                  )
                                ) {
                                  this.handleRestore();
                                }
                              }}
                              size="sm"
                              style={{
                                backgroundColor: "orange",
                                borderColor: "orange",
                              }}
                            >
                              <div
                                style={{ backgroundColor: "orange !important" }}
                                className="icon custom-icon icon-shape text-white rounded-circle shadow"
                              >
                                <i className="fas fa-undo" />
                              </div>
                              Reset
                            </Button>
                          ) : (
                            ""
                          )}

                          {this.state.createAllowed && (
                            <Button
                              style={{
                                background: "#df7126",
                                borderColor: "#df7126",
                              }}
                              color="primary"
                              onClick={() => {
                                this.setState({ open: true });
                              }}
                              size="xs"
                            >
                              Upload Frequency
                            </Button>
                          )}

                          <Button
                            color="danger"
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you want to unassign the sensor?"
                                )
                              ) {
                                this.handleUnassign();
                              }
                            }}
                            size="sm"
                          >
                            <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-power-off" />
                            </div>
                            Un-assign
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>

                    {this.state.currentPage > 0 && (
                      <CardBody>
                        {this.state.currentPage === 1 && (
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                  >
                                    Sensor Id
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-username"
                                    type="text"
                                    value={sensorId}
                                    onChange={this.handleChange(
                                      "sensor",
                                      "sensorId"
                                    )}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                  >
                                    Tag Id
                                  </label>
                                  <Select
                                    isClearable={false}
                                    isDisabled={true}
                                    onChange={this.handleSelectChange(
                                      "sensor",
                                      "tagId"
                                    )}
                                    value={
                                      this.state.tagOptions[
                                        this.state.defaultTagId
                                      ]
                                    }
                                    options={this.state.tagOptions}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {this.state.currentPage === 2 && (
                          <>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Sensor Types:
                                </label>
                                <Select
                                  isClearable={false}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorType"
                                  )}
                                  defaultValue={
                                    this.state.sensorTypes[
                                      this.state.defaultSensorType
                                    ]
                                  }
                                  options={this.state.sensorTypes}
                                />
                              </FormGroup>
                            </Col>

                            <div className="pl-lg-4">
                              <Tabs>
                                <TabList>
                                  {this.state.sensorUnit.length &&
                                    this.state.sensorUnit.map((tab, i) => (
                                      <Tab key={i}>
                                        {tab.charAt(0).toUpperCase() +
                                          tab.slice(1)}
                                      </Tab>
                                    ))}
                                </TabList>
                                <br />
                                {this.state.sensorConfigurations.length &&
                                  this.state.sensorConfigurations.map(
                                    (field, j) => {
                                      if (field != "sensor") {
                                        return (
                                          <TabPanel key={j}>
                                            {Object.keys(field).map((key) => {
                                              if (key != "sensor") {
                                                return (
                                                  <Row key={key + j}>
                                                    <Col lg="1"></Col>
                                                    <Col lg="3">
                                                      <FormGroup>
                                                        <label className="form-control-label">
                                                          {key}:
                                                        </label>
                                                      </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                      <FormGroup>
                                                        {key !=
                                                        "adcConversion" ? (
                                                          <Input
                                                            className="form-control-alternative"
                                                            id="input-username"
                                                            type="text"
                                                            value={field[key]}
                                                            readOnly={
                                                              this.state
                                                                .readOnly
                                                            }
                                                            onChange={this.handleChange(
                                                              "sensorConfigurations",
                                                              key,
                                                              j
                                                            )}
                                                          />
                                                        ) : (
                                                          <Checkbox
                                                            checked={field[key]}
                                                            onChange={this.handleChange(
                                                              "sensorConfigurations",
                                                              key,
                                                              j,
                                                              "checkbox"
                                                            )}
                                                            color="primary"
                                                            inputProps={{
                                                              "aria-label":
                                                                "primary checkbox",
                                                            }}
                                                            disabled={
                                                              this.state
                                                                .readOnly
                                                            }
                                                          />
                                                        )}
                                                      </FormGroup>
                                                    </Col>
                                                    <Col lg="2"></Col>
                                                  </Row>
                                                );
                                              }
                                            })}
                                          </TabPanel>
                                        );
                                      }
                                    }
                                  )}
                              </Tabs>
                            </div>
                          </>
                        )}
                        {this.state.currentPage === 3 && (
                          <>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Firmware Types:
                                </label>
                                <Select
                                  isClearable={false}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "firmwareType"
                                  )}
                                  defaultValue={
                                    this.state.firmwareTypes[
                                      this.state.defaultFirmwareType
                                    ]
                                  }
                                  options={this.state.firmwareTypes}
                                />
                              </FormGroup>
                            </Col>

                            <div className="pl-lg-4">
                              <Tabs>
                                <TabList>
                                  {this.state.sensorUnit.length &&
                                    this.state.sensorUnit.map((tab, i) => (
                                      <Tab key={i}>
                                        {tab.charAt(0).toUpperCase() +
                                          tab.slice(1)}
                                      </Tab>
                                    ))}
                                  {/* <Tab>ADC Convertion</Tab> */}
                                </TabList>
                                <br />
                                {this.state.firmwareConfigurations &&
                                  this.state.firmwareConfigurations.length &&
                                  this.state.firmwareConfigurations.map(
                                    (field, j) => {
                                      if (field != "sensor") {
                                        return (
                                          <TabPanel key={j}>
                                            {Object.keys(field).map((key) => {
                                              if (key != "sensor") {
                                                return (
                                                  <Row key={key + j}>
                                                    <Col lg="1"></Col>
                                                    <Col lg="3">
                                                      <FormGroup>
                                                        <label className="form-control-label">
                                                          {key}:
                                                        </label>
                                                      </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                      <FormGroup>
                                                        {key !=
                                                        "adcConvertion" ? (
                                                          <Input
                                                            className="form-control-alternative"
                                                            id="input-username"
                                                            type="text"
                                                            value={field[key]}
                                                            // onChange={this.handleChange(key, field)}
                                                            onChange={this.handleChange(
                                                              "firmwareConfigurations",
                                                              key,
                                                              j
                                                            )}
                                                          />
                                                        ) : (
                                                          <Input
                                                            className="form-control-alternative"
                                                            id="input-check"
                                                            type="checkbox"
                                                            checked={field[key]}
                                                            // onChange={this.handleChange('adcConvertion', 'adcConvertion', 'checkbox')}
                                                            onChange={this.handleChange(
                                                              "firmwareConfigurations",
                                                              key,
                                                              j,
                                                              field,
                                                              "checkbox"
                                                            )}
                                                          />
                                                        )}
                                                      </FormGroup>
                                                    </Col>
                                                    <Col lg="2"></Col>
                                                  </Row>
                                                );
                                              }
                                            })}
                                          </TabPanel>
                                        );
                                      }
                                    }
                                  )}
                              </Tabs>
                            </div>
                          </>
                        )}
                        {this.state.currentPage === 4 && (
                          <>
                            <div className="pl-lg-4">
                              <Tabs onSelect={this.handleTabChange}>
                                <TabList>
                                  {Object.keys(
                                    this.state.processConfiguration
                                  ).map((field, i) => {
                                    return (
                                      <Tab
                                        style={{
                                          backgroundColor: "#FFB74D",
                                          color: "#fff",
                                        }}
                                      >
                                        {field}
                                      </Tab>
                                    );
                                  })}
                                </TabList>

                                {processConfig}
                              </Tabs>
                            </div>
                          </>
                        )}

                        {this.state.currentPage === 5 && (
                          <div className="pl-lg-4">
                            <>
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <label className="form-control-label">
                                      Mount Configurations
                                    </label>
                                    <Select
                                      isClearable={false}
                                      isDisabled={this.state.readOnly}
                                      onChange={this.handleSelectChange(
                                        "mountType"
                                      )}
                                      defaultValue={
                                        this.state.mountConfigurations[
                                          this.state.defaultMountType
                                        ]
                                      }
                                      options={this.state.mountConfigurations}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col lg="12">
                                  <AxisImage />
                                </Col>

                                {/* <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label">
                                  Horizontal:
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={
                                    this.state.sensorMountInfo.mountMapping
                                      .horizontal
                                  }
                                  // onChange={this.handleChange('sensorMountInfo', 'description')}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label">
                                  Vertical:
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={
                                    this.state.sensorMountInfo.mountMapping
                                      .vertical
                                  }
                                  // onChange={this.handleChange('sensorMountInfo', 'description')}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label">
                                  Axial:
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={
                                    this.state.sensorMountInfo.mountMapping
                                      .axial
                                  }
                                  // onChange={this.handleChange('sensorMountInfo', 'description')}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  Mount Type:
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={this.state.sensorMountInfo.mountType}
                                  onChange={this.handleChange(
                                    "sensorMountInfo",
                                    "description"
                                  )}
                                />
                              </FormGroup>
                            </Col> */}
                              </Row>
                            </>
                          </div>
                        )}
                        {this.state.currentPage <= 5 && (
                          <Row>
                            <Col lg="12">
                              {
                                <Button
                                  className="right"
                                  color="success"
                                  onClick={() => {
                                    this.state.currentPage === 1
                                      ? this.handleNext(4)
                                      : this.handleNext(
                                          this.state.currentPage + 1
                                        );
                                  }}
                                  size="md"
                                  disabled={this.state.saving}
                                >
                                  {this.state.saving ? (
                                    <i className="fas fa-spinner fa-pulse"></i>
                                  ) : (
                                    <>
                                      {front}
                                      <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                        <i className="fa fa-forward" />
                                      </div>
                                    </>
                                  )}
                                </Button>
                              }

                              {this.state.currentPage != 1 && (
                                <Button
                                  className="right cmr-10"
                                  color="danger"
                                  onClick={() =>
                                    this.handleCancel(
                                      this.state.pages[
                                        this.state.currentPage === 4
                                          ? 1
                                          : this.state.currentPage - 1
                                      ]
                                    )
                                  }
                                  size="md"
                                >
                                  {
                                    <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                      <i className="fa fa-backward" />
                                    </div>
                                  }
                                  {back}
                                </Button>
                              )}
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    )}
                  </Card>
                </div>
              </Row>
            </Container>
            <Dialog
              fullWidth={true}
              maxWidth={"sm"}
              scroll={"body"}
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Upload Frequency</DialogTitle>
              <DialogContent style={{ height: "250px" }}>
                <Select
                  className="form-control-alternative"
                  isClearable={false}
                  onChange={this.handleSeletChangeUploadRate.bind(this)}
                  placeholder=""
                  options={this.state.frequency}
                  value={this.state.frequency[this.state.selectedFrequency]}
                  // isDisabled={this.state.readOnly}
                  fullWidth
                  margin="dense"
                  autoFocus
                />

                <br></br>
                {this.state.sensorsUploadFrequencySuccess.length > 0 && (
                  <center>
                    <h4 style={{ color: "green" }}>Sensors Updated</h4>
                  </center>
                )}
                {this.state.sensorsUploadFrequencySuccess.length > 0 &&
                  this.state.sensorsUploadFrequencySuccess.map((sensor) => {
                    return (
                      sensor.resStatus == 200 && (
                        <Chip
                          style={{
                            border: "1px solid #2dce89",
                            color: "#000",
                            margin: "2px",
                          }}
                          label={sensor.sensorId}
                          color="primary"
                          variant="outlined"
                        />
                      )
                    );
                  })}
                <br></br>
                <br></br>
                {this.state.sensorsUploadFrequencyError.length > 0 && (
                  <center>
                    <h4 style={{ color: "#f5365c" }}>
                      Electric Imp Exception in Sensors
                    </h4>
                  </center>
                )}
                {this.state.sensorsUploadFrequencyError.length > 0 &&
                  this.state.sensorsUploadFrequencyError.map((sensor) => {
                    return (
                      sensor.resStatus != 200 && (
                        <Chip
                          style={{
                            border: "1px solid #f5365c",
                            color: "#000",
                            margin: "2px",
                          }}
                          label={sensor.sensorId}
                          color="primary"
                          variant="outlined"
                        />
                      )
                    );
                  })}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="danger">
                  Close
                </Button>
                <Button
                  onClick={this.handleUploadRateSubmit}
                  color="success"
                  disabled={this.state.saving}
                >
                  {this.state.saving ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        ;
      </>
    );
  }
}

export default SensorDetails;
