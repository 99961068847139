import React from "react";
import { Form, Input, Button, Typography, InputNumber, Radio } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import BearingCustom from "./bearingCustom";
import API from "../../../../../axios";
import GearBoxImg from "./gearbox.png";

const ANS_JournalBearing = "Journal Bearing";
const ANS_AntiFrictionBearing = "Anti-Friction Bearing";
const ANS_Unknown = "Unknown";

const formItemLayout = {
  labelCol: { xs: { span: 12 }, sm: { span: 12 } },
  wrapperCol: { xs: { span: 12 }, sm: { span: 12 } },
};

const OptionBearingType = [
  { value: "0", label: ANS_JournalBearing },
  { value: "1", label: ANS_AntiFrictionBearing },
  { value: "2", label: ANS_Unknown },
];
const rpmOut = (inputRpm, T1, T2) => {
  if (inputRpm && T1 && T2) {
    return parseFloat((inputRpm * (T1 / T2)).toFixed(2));
  }
  return null; // Return null if any input is invalid
};

const isMaxGreaterThanMin = (min, max) => {
  if (Number(min) >= Number(max)) {
    return Promise.reject("Max RPM should be greater than Min RPM");
  }
  return Promise.resolve();
};

const GMFCustom = ({ options, value, ...props }) => {
  const [form] = Form.useForm();
  const isAddStageVisible = (fields) => {
    if (fields.length === 0) return true; // Show button if no stages exist
    if (fields.length >= 3) return false; // Disable the button after stage 3

    const updatedData = [...value]; // Assuming `value` contains the form values

    // Loop through stages dynamically
    for (let i = 0; i < fields.length; i++) {
      const currentStage = updatedData[i] || {};
      const { noOfTeeth1, noOfTeeth2 } = currentStage;

      // If any stage is incomplete, return false
      if (!noOfTeeth1 || !noOfTeeth2) return false;
    }

    return true; // Show button if all stages are valid
  };

  const [selectedBearing, setSelectedBearing] = React.useState(null);
  console.log("GMF Props ---", value);

  const getRpmOut = (index, minCalculationFlag) => {
    const Val = value[index];
    if (!Val) return "";

    const { noOfTeeth1: T1, noOfTeeth2: T2, speedInRpm, minRPM, maxRPM } = Val;

    if (T1 && T2 && speedInRpm) {
      return rpmOut(speedInRpm, T1, T2);
    } else {
      return rpmOut(minRPM, T1, T2);
    }
    return null;
  };

  function handleSpeedOutRpmChange(
    field,
    getFieldValue,
    setFieldsValue,
    index,
    getRpmOut
  ) {
    const updatedData = [...value];
    const rpmValue = getRpmOut(index, false);

    const currentValue = getFieldValue([field.name, "speedOutRpm"]);
    if (currentValue !== rpmValue) {
      setFieldsValue({
        [field.name]: { ...getFieldValue(field.name), speedOutRpm: rpmValue },
      });
    }

    // Check if speedOutRpm in updatedData differs from rpmValue
    if (updatedData[index] && updatedData[index].speedOutRpm !== rpmValue) {
      updatedData[index]["speedOutRpm"] = rpmValue;

      // Call props.onChange only when the data is actually updated
      props.onChange(updatedData);
    }
  }

  const handleRadioChange = (stage, field, newValue) => {
    const updatedData = [...value];
    if (!updatedData[stage]) updatedData[stage] = {};
    if (!updatedData[stage][field]) updatedData[stage][field] = {};
    updatedData[stage][field].bearingType = parseInt(newValue);

    // Set bearingFaults to an empty array if newValue is 0 or 2
    if (parseInt(newValue) === 0 || parseInt(newValue) === 2) {
      updatedData[stage][field].bearingFaults = [];
    }
    // Initialize bearingFaults as an empty array if newValue is 1 and bearingFaults is not defined
    if (
      parseInt(newValue) === 1 &&
      !Array.isArray(updatedData[stage][field].bearingFaults)
    ) {
      updatedData[stage][field].bearingFaults = [];
    }
    props.onChange(updatedData); // Propagate the change to parent
    setSelectedBearing({ ...selectedBearing, [stage]: newValue });
  };

  const handleBearingDetailsChange = (stage, field, newDetails) => {
    const updatedData = [...value];
    if (!updatedData[stage]) updatedData[stage] = {};
    if (!updatedData[stage][field]) updatedData[stage][field] = {};

    // Extract and transform options into the required format
    // Determine if `newDetails.option` is an array or a single object
    let transformedDetails = [];

    if (newDetails && newDetails.option) {
      if (Array.isArray(newDetails.option)) {
        // Handle array case
        transformedDetails = newDetails.option.map(function (detail) {
          const [shaftBearingNumber, shaftBearingMake] = (
            detail.value || ""
          ).split(" || ");
          return {
            shaftBearingNumber: shaftBearingNumber || "undefined",
            shaftBearingMake: shaftBearingMake || "undefined",
          };
        });
      } else {
        // Handle single object case
        const [shaftBearingNumber, shaftBearingMake] = (
          newDetails.option.value || ""
        ).split(" || ");
        transformedDetails = [
          {
            shaftBearingNumber: shaftBearingNumber || "undefined",
            shaftBearingMake: shaftBearingMake || "undefined",
          },
        ];
      }
    }

    // Assign transformed details to `bearingFaults`
    updatedData[stage][field].bearingFaults = transformedDetails;

    props.onChange(updatedData); // Propagate the change to parent
  };

  const { isConstantSpeed } = props;

  const getBearingList = async (inputValue) => {
    if (inputValue.length < 2) {
      return [{ label: "NA", value: "NA" }];
    }

    try {
      const response = await API.bearing_list(inputValue);
      if (response && response.status === 200) {
        const bearingData = (response.data && response.data.data) || [];
        return [
          { label: "NA", value: "NA" },
          ...bearingData.map((bearing) => ({
            value: `${bearing.BearingNumber} || ${bearing.BearingMake}`,
            label: `${bearing.BearingNumber} || ${bearing.BearingMake}`,
          })),
        ];
      }
    } catch (error) {
      console.error("Error fetching bearing list", error);
    }

    return [{ label: "NA", value: "NA" }];
  };

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(getBearingList(inputValue));
    });

  return (
    <Form.List
      name={props.id}
      rules={[
        {
          validator: async (_, names) => {
            if (!names || names.length === 0) {
              return Promise.reject(new Error("At least 1 bearing"));
            }
          },
          type: "array",
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          <img src={GearBoxImg} width={250} height={200} alt="Gearbox" />

          {fields.map((field, index) => (
            <Form.Item required={false} name={`${index}`} key={field.key}>
              {isConstantSpeed ? (
                <>
                  {index === 0 && (
                    <Form.Item
                      {...formItemLayout}
                      label="What is input shaft min RPM?"
                      name={[field.name, "minRPM"]}
                      rules={[
                        { required: true, message: "Please input min RPM" },
                      ]}
                      normalize={(value) =>
                        value ? parseInt(value, 10) : value
                      } // Transform input to integer
                    >
                      <InputNumber
                        placeholder="What is input shaft min RPM?"
                        style={{ width: "50%" }}
                      />
                    </Form.Item>
                  )}
                </>
              ) : (
                <>
                  {index === 0 && (
                    <>
                      <Form.Item
                        {...formItemLayout}
                        label="What is input shaft min RPM?"
                        name={[field.name, "minRPM"]}
                        rules={[
                          { required: true, message: "Please input min RPM" },
                        ]}
                        normalize={(value) =>
                          value ? parseInt(value, 10) : value
                        } // Transform input to integer
                      >
                        <InputNumber
                          placeholder="What is input shaft min RPM?"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

                      {/* //max rp/ */}
                      <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue, setFieldsValue }) => (
                          <Form.Item
                            {...formItemLayout}
                            label="What is input shaft max RPM?"
                            name={[field.name, "maxRPM"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input max RPM",
                              },
                              {
                                validator: (_, value) => {
                                  const minRPM = getFieldValue([
                                    props.id,
                                    field.name,
                                    "minRPM",
                                  ]);
                                  return isMaxGreaterThanMin(minRPM, value);
                                },
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="What is input shaft max RPM?"
                              style={{ width: "100%" }}
                              onBlur={(e) => {
                                const updatedData = [...value]; // Copy the current state of data

                                if (index === 0) {
                                  // Store maxRPM as speedInRpm when index is 0
                                  const newMaxRPM =
                                    parseInt(e.target.value) || "";
                                  updatedData[index] = {
                                    ...updatedData[index],
                                    speedInRpm: newMaxRPM, // Set speedInRpm equal to maxRPM
                                  };
                                }

                                // Propagate the changes back to the parent or form state
                                props.onChange(updatedData);
                              }}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>
                    </>
                  )}
                </>
              )}
              <Typography.Title level={4}>
                What are the Stage {index + 1} Details?
              </Typography.Title>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, setFieldsValue }) => {
                  const updatedData = [...value]; // Store all data

                  const handleTeethChange = (newValue) => {
                    // Parse the input value into an integer
                    const parsedValue = parseInt(newValue, 10);

                    if (isNaN(parsedValue)) {
                      return; // Do nothing if the input is not a valid number
                    }

                    // Update current field's value with the parsed integer
                    updatedData[index] = {
                      ...updatedData[index],
                      noOfTeeth1: parsedValue,
                    };

                    // // If index > 0, copy maxRPM and minRPM from index 0
                    // if (index > 0) {
                    //   const firstIndexData =
                    //     getFieldValue([props.id, fields[0].name]) || {};

                    //   updatedData[index] = {
                    //     ...updatedData[index],
                    //     maxRPM: firstIndexData.maxRPM || "",
                    //     minRPM: firstIndexData.minRPM || "",
                    //   };
                    // }

                    // Propagate changes back to the parent
                    props.onChange(updatedData);

                    // Reflect changes in the form fields
                    setFieldsValue({ [field.name]: updatedData[index] });
                  };

                  return (
                    <Form.Item
                      label="Number of teeth In (T1)"
                      name={[field.name, "noOfTeeth1"]}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input Number of teeth T1",
                      //   },
                      // ]}
                    >
                      <Input
                        placeholder="Number of teeth In (T1)"
                        value={getFieldValue([field.name, "noOfTeeth1"]) || ""}
                        onChange={(e) => handleTeethChange(e.target.value)}
                        style={{ width: "40%" }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>

              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, setFieldsValue }) => {
                  const updatedData = [...value]; // Store all data

                  const handleTeethChange = (newValue) => {
                    // Parse the input value into an integer
                    const parsedValue = parseInt(newValue, 10);

                    if (isNaN(parsedValue)) {
                      return; // Do nothing if the input is not a valid number
                    }
                    // Update current field's value
                    updatedData[index] = {
                      ...updatedData[index],
                      noOfTeeth2: parsedValue,
                    };

                    // If index > 0, update the speedInRpm of the current index
                    if (index > 0) {
                      const previousSpeedOutRpm =
                        getFieldValue([
                          props.id,
                          fields[index - 1].name,
                          "speedOutRpm",
                        ]) || "";

                      updatedData[index] = {
                        ...updatedData[index],
                        speedInRpm: previousSpeedOutRpm, // Set speedInRpm from previous speedOutRpm
                      };
                    }

                    // Calculate and set speedOutRpm using the provided getRpmOut function
                    const speedOutRpm = getRpmOut(index, false);
                    updatedData[index] = {
                      ...updatedData[index],
                      speedOutRpm, // Set calculated speedOutRpm
                    };

                    // Propagate changes back to the parent
                    props.onChange(updatedData);

                    // Reflect changes in the form fields
                    setFieldsValue({ [field.name]: updatedData[index] });
                  };

                  return (
                    <Form.Item
                      label="Number of teeth Out (T2)"
                      name={[field.name, "noOfTeeth2"]}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input Number of teeth T2",
                      //   },
                      // ]}
                    >
                      <Input
                        placeholder="Number of teeth Out (T2)"
                        value={getFieldValue([field.name, "noOfTeeth2"]) || ""}
                        onChange={(e) => handleTeethChange(e.target.value)}
                        style={{ width: "40%" }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              {index === 0 && (
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => (
                    <Form.Item label="Speed In RPM">
                      <Input
                        value={
                          getFieldValue([props.id, field.name, "speedInRpm"]) ||
                          getFieldValue([props.id, field.name, "minRPM"])
                        }
                        readOnly
                        style={{
                          width: "40%",
                          backgroundColor: "#f5f5f5", // Light grey background
                          color: "#000", // Black text color for better contrast
                          border: "1px solid #d9d9d9", // Grey border for consistency
                          cursor: "not-allowed", // Indicates the field is read-only
                        }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              )}
              {index > 0 && (
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const previousSpeedOutRpm =
                      getFieldValue([
                        props.id,
                        fields[index - 1].name,
                        "speedOutRpm",
                      ]) || "";

                    return (
                      <Form.Item label="Speed In RPM">
                        <Input
                          value={previousSpeedOutRpm || ""}
                          readOnly
                          style={{
                            width: "40%",
                            backgroundColor: "#f5f5f5",
                            color: "#000",
                            border: "1px solid #d9d9d9",
                            cursor: "not-allowed",
                          }}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              )}

              <Form.Item shouldUpdate noStyle>
                {function ({ getFieldValue, setFieldsValue }) {
                  // Ensure `handleSpeedOutRpmChange` is invoked when the component renders
                  handleSpeedOutRpmChange(
                    field,
                    getFieldValue,
                    setFieldsValue,
                    index,
                    getRpmOut
                  );

                  return (
                    <Form.Item
                      name={[field.name, "speedOutRpm"]}
                      initialValue={getRpmOut(index, false)} // Initialize with calculated value
                    >
                      <Form.Item label="Speed Out RPM">
                        <Input
                          value={
                            getFieldValue([field.name, "speedOutRpm"]) ||
                            getRpmOut(index, false) ||
                            ""
                          }
                          readOnly
                          style={{
                            width: "40%",
                            backgroundColor: "#f5f5f5", // Light grey background
                            color: "#000", // Black text color for better contrast
                            border: "1px solid #d9d9d9", // Grey border for consistency
                            cursor: "not-allowed", // Indicates the field is read-only
                          }}
                        />
                      </Form.Item>
                    </Form.Item>
                  );
                }}
              </Form.Item>
              {/* shaft details */}
              <Form layout="vertical">
                {index === 0 && (
                  <>
                    {/* Shaft 1 */}
                    <div>
                      <h5>Shaft 1</h5>
                      What is bearing type, number, and make?
                      <Form.Item
                        name={[`shaftBearingDetails`, index]}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please select a bearing type for Shaft 1",
                        //   },
                        // ]}
                      >
                        <Radio.Group
                          options={OptionBearingType}
                          onChange={(e) =>
                            handleRadioChange(
                              index,
                              `shaftBearingDetails`,
                              e.target.value
                            )
                          }
                          defaultValue={
                            value[0] &&
                            value[0].shaftBearingDetails &&
                            value[0].shaftBearingDetails.bearingType === 1
                              ? "1"
                              : value[0] &&
                                value[0].shaftBearingDetails &&
                                value[0].shaftBearingDetails.bearingType === 0
                              ? "0"
                              : value[0] &&
                                value[0].shaftBearingDetails &&
                                value[0].shaftBearingDetails.bearingType === 2
                              ? "2"
                              : null
                          }
                        />
                      </Form.Item>
                      {value[0] &&
                        value[0].shaftBearingDetails &&
                        value[0].shaftBearingDetails.bearingType === 1 && (
                          <div className="mt-4">
                            <Form.Item
                              name={[`bearingDetails_shaft1`, index]}
                              initialValue={(Array.isArray(
                                value &&
                                  value.length > 0 &&
                                  value[0].shaftBearingDetails &&
                                  value[0].shaftBearingDetails.bearingFaults
                                  ? value[0].shaftBearingDetails.bearingFaults
                                  : []
                              )
                                ? value[0].shaftBearingDetails.bearingFaults
                                : []
                              ).map(function (fault) {
                                return {
                                  label:
                                    (fault.shaftBearingNumber || "Unknown") +
                                    " || " +
                                    (fault.shaftBearingMake || "Unknown"),
                                  value:
                                    (fault.shaftBearingNumber || "Unknown") +
                                    " || " +
                                    (fault.shaftBearingMake || "Unknown"),
                                };
                              })}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message:
                              //       "Please select bearing details for Shaft 1",
                              //   },
                              // ]}
                            >
                              <BearingCustom
                                isMulti
                                showSearch
                                loadOptions={loadOptions}
                                name={`GearBoxBearing_shaft1`}
                                onChange={(e, obj) =>
                                  handleBearingDetailsChange(
                                    index,
                                    `shaftBearingDetails`,
                                    obj
                                  )
                                }
                                options={options}
                              />
                            </Form.Item>
                          </div>
                        )}
                    </div>

                    {/* Shaft 2 */}
                    <div>
                      <h5>Shaft 2</h5>
                      What is bearing type, number, and make?
                      <Form.Item
                        name={[`shaft2BearingDetails`, index]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a bearing type for Shaft 2",
                          },
                        ]}
                      >
                        <Radio.Group
                          options={OptionBearingType}
                          onChange={(e) =>
                            handleRadioChange(
                              index,
                              `shaft2BearingDetails`,
                              e.target.value
                            )
                          }
                          defaultValue={
                            value[0] &&
                            value[0].shaft2BearingDetails &&
                            value[0].shaft2BearingDetails.bearingType === 1
                              ? "1"
                              : value[0] &&
                                value[0].shaft2BearingDetails &&
                                value[0].shaft2BearingDetails.bearingType === 0
                              ? "0"
                              : value[0] &&
                                value[0].shaft2BearingDetails &&
                                value[0].shaft2BearingDetails.bearingType === 2
                              ? "2"
                              : null
                          }
                        />
                      </Form.Item>
                      {value[0] &&
                        value[0].shaft2BearingDetails &&
                        value[0].shaft2BearingDetails.bearingType === 1 && (
                          <div className="mt-4">
                            <Form.Item
                              name={[`bearingDetails_shaft2`, index]}
                              initialValue={(Array.isArray(
                                value &&
                                  value.length > 0 &&
                                  value[0].shaft2BearingDetails &&
                                  value[0].shaft2BearingDetails.bearingFaults
                                  ? value[0].shaft2BearingDetails.bearingFaults
                                  : []
                              )
                                ? value[0].shaft2BearingDetails.bearingFaults
                                : []
                              ).map(function (fault) {
                                return {
                                  label:
                                    (fault.shaftBearingNumber || "Unknown") +
                                    " || " +
                                    (fault.shaftBearingMake || "Unknown"),
                                  value:
                                    (fault.shaftBearingNumber || "Unknown") +
                                    " || " +
                                    (fault.shaftBearingMake || "Unknown"),
                                };
                              })}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please select bearing details for Shaft 2",
                                },
                              ]}
                            >
                              <BearingCustom
                                isMulti
                                showSearch
                                loadOptions={loadOptions}
                                name={`GearBoxBearing_shaft2`}
                                onChange={(e, obj) =>
                                  handleBearingDetailsChange(
                                    index,
                                    `shaft2BearingDetails`,
                                    obj
                                  )
                                }
                                options={options}
                              />
                            </Form.Item>
                          </div>
                        )}
                    </div>
                  </>
                )}

                {index === 1 && (
                  <>
                    {/* Shaft 3 */}
                    <div>
                      <h5>Shaft 3</h5>
                      What is bearing type, number, and make?
                      <Form.Item
                        name={[`bearingType_shaft3`, index]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a bearing type for Shaft 3",
                          },
                        ]}
                      >
                        <Radio.Group
                          options={OptionBearingType}
                          onChange={(e) =>
                            handleRadioChange(
                              index,
                              `shaftBearingDetails`,
                              e.target.value
                            )
                          }
                          defaultValue={
                            value[1] &&
                            value[1].shaftBearingDetails &&
                            value[1].shaftBearingDetails.bearingType === 1
                              ? "1"
                              : value[1] &&
                                value[1].shaftBearingDetails &&
                                value[1].shaftBearingDetails.bearingType === 0
                              ? "0"
                              : value[1] &&
                                value[1].shaftBearingDetails &&
                                value[1].shaftBearingDetails.bearingType === 2
                              ? "2"
                              : null
                          }
                        />
                      </Form.Item>
                      {value[1] &&
                        value[1].shaftBearingDetails &&
                        value[1].shaftBearingDetails.bearingType === 1 && (
                          <div className="mt-4">
                            <Form.Item
                              name={[`bearingDetails_shaft3`, index]}
                              initialValue={(Array.isArray(
                                value &&
                                  value.length > 0 &&
                                  value[0].shaftBearingDetails &&
                                  value[0].shaftBearingDetails.bearingFaults
                                  ? value[0].shaftBearingDetails.bearingFaults
                                  : []
                              )
                                ? value[0].shaftBearingDetails.bearingFaults
                                : []
                              ).map(function (fault) {
                                return {
                                  label:
                                    (fault.shaftBearingNumber || "Unknown") +
                                    " || " +
                                    (fault.shaftBearingMake || "Unknown"),
                                  value:
                                    (fault.shaftBearingNumber || "Unknown") +
                                    " || " +
                                    (fault.shaftBearingMake || "Unknown"),
                                };
                              })}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please select bearing details for Shaft 3",
                                },
                              ]}
                            >
                              <BearingCustom
                                isMulti
                                showSearch
                                loadOptions={loadOptions}
                                // name={`GearBoxBearing_shaft3`}
                                onChange={(e, obj) =>
                                  handleBearingDetailsChange(
                                    index,
                                    `shaftBearingDetails`,
                                    obj
                                  )
                                }
                                options={options}
                              />
                            </Form.Item>
                          </div>
                        )}
                    </div>
                  </>
                )}

                {index === 2 && (
                  <>
                    {/* Shaft 3 */}
                    <div>
                      <h5>Shaft 4</h5>
                      What is bearing type, number, and make?
                      <Form.Item
                        name={[`shaftBearingDetails`, index]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a bearing type for Shaft 3",
                          },
                        ]}
                      >
                        <Radio.Group
                          options={OptionBearingType}
                          onChange={(e) =>
                            handleRadioChange(
                              index,
                              `shaftBearingDetails`,
                              e.target.value
                            )
                          }
                          defaultValue={
                            value[2] &&
                            value[2].shaftBearingDetails &&
                            value[2].shaftBearingDetails.bearingType === 1
                              ? "1"
                              : value[2] &&
                                value[2].shaftBearingDetails &&
                                value[2].shaftBearingDetails.bearingType === 0
                              ? "0"
                              : value[2] &&
                                value[2].shaftBearingDetails &&
                                value[2].shaftBearingDetails.bearingType === 2
                              ? "2"
                              : null
                          }
                        />
                      </Form.Item>
                      {value[2] &&
                        value[2].shaftBearingDetails &&
                        value[2].shaftBearingDetails.bearingType === 1 && (
                          <div className="mt-4">
                            <Form.Item
                              name={[`bearingDetails_shaft3`, index]}
                              initialValue={(Array.isArray(
                                value &&
                                  value.length > 0 &&
                                  value[0].shaftBearingDetails &&
                                  value[0].shaftBearingDetails.bearingFaults
                                  ? value[0].shaftBearingDetails.bearingFaults
                                  : []
                              )
                                ? value[0].shaftBearingDetails.bearingFaults
                                : []
                              ).map(function (fault) {
                                return {
                                  label:
                                    (fault.shaftBearingNumber || "Unknown") +
                                    " || " +
                                    (fault.shaftBearingMake || "Unknown"),
                                  value:
                                    (fault.shaftBearingNumber || "Unknown") +
                                    " || " +
                                    (fault.shaftBearingMake || "Unknown"),
                                };
                              })}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please select bearing details for Shaft 3",
                                },
                              ]}
                            >
                              <BearingCustom
                                isMulti
                                showSearch
                                loadOptions={loadOptions}
                                // name={`GearBoxBearing_shaft3`}
                                onChange={(e, obj) =>
                                  handleBearingDetailsChange(
                                    index,
                                    `shaftBearingDetails`,
                                    obj
                                  )
                                }
                                options={options}
                              />
                            </Form.Item>
                          </div>
                        )}
                    </div>
                  </>
                )}
              </Form>
              <MinusCircleOutlined
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            </Form.Item>
          ))}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                if (fields.length < 3) {
                  add();
                }
              }}
              disabled={!isAddStageVisible(fields)} // Conditionally show button
              style={{ width: "60%" }}
              icon={<PlusOutlined />}
            >
              Add stage
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default GMFCustom;
